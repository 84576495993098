import { WithTranslation } from 'react-i18next';

import { Weekday } from 'models/product';

export const getWeekdayText = (weekday: Weekday, t: WithTranslation['t']): string => {
  switch (weekday) {
    case 'SUN':
      return t('Sun');
    case 'MON':
      return t('Mon');
    case 'TUE':
      return t('Tue');
    case 'WED':
      return t('Wed');
    case 'THU':
      return t('Thu');
    case 'FRI':
      return t('Fri');
    case 'SAT':
      return t('Sat');
    default:
      return '';
  }
};
